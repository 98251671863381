/* Width and color of the scrollbar track */
::-webkit-scrollbar {
  width: 12px;
  background-color: #2B3035;
}

/* Styles for the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #8a8a8a;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #EF8354;
}

.nav-links {
  justify-content: center;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

#link {
  color: #8a8a8a;
}

#link:hover {
  color: #ffffff;
}

#link.active {
  color: #EF8354;
  /* Color of the links after they are clicked */
}

.textStyle-responsive {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 6vw;
  color: #ffffff81;
  font-family: 'Bowlby One ' sans-serif;
  font-weight: 900;
  text-align: center;
  width: 65%;
  line-height: 1;
}



.textStyle-responsive-about{
  position: absolute;
  top: 20%;
  left: 30%;
  transform: translate(-50%, -50%);
  font-size: 9vw;
  color: #ffffff81;
  font-family: 'Bowlby One ' sans-serif;
  font-weight: 900;
  text-align: center;
}

.introsection {
  height: 350px;
  background-color: #EF8354;
  padding: 150px;
  display: flex;

}

.textposition {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.firstcol {
  font-size: 48px;
  font-family: 'Bowlby One ' sans-serif;
  color: #2B3035;
  font-weight: 900;
  text-align: center;
  width: 100%;
}

.callactioncol{
  font-size: 40px;
  font-family: 'Bowlby One ' sans-serif;
  color: #2B3035;
  font-weight: 900;
  text-align: start;
  width: 100%;
}

.secondcol {
  font-family: 'Bowlby One ' sans-serif;
  color: #2B3035;
  font-size: 18px;
  font-weight: bold;
}

.callaction {
  height: auto;
margin-top: 150px;}

.sub-title{
  font-size: 18px;
  margin-bottom: 30px;
  font-family: 'Bowlby One ' sans-serif;
}
 .about-container{
  margin-top: 40px;

 }

.metallist {
  display: flex;
  gap: 20px;
  justify-content: end;
  flex-wrap: wrap;

}


.callactionbtn {
  margin-top: 10px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
}

/* KeyServices.css */
.imageeffect {
  filter: grayscale(100%)
}

.imageeffect:hover {
  filter: grayscale(0%);
}

.title-header {
  font-family: 'Bowlby One ' sans-serif;
  color: #2B3035;
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 40px;

}

.orange-bg {
  background-color: #EF8354;
}

.form-label {
  font-family: 'Bowlby One ' sans-serif;
  color: #2B3035;
  font-size: 18px;
  font-weight: bold;
}

.faq-title {
  font-family: 'Bowlby One ' sans-serif;
  color: #2B3035;
  font-weight: 800;
}

.faq-text {
  font-family: 'Bowlby One ' sans-serif;
  color: #2B3035;
  font-weight: bold;
}

.footer-bg {
  color: #8a8a8a;
  background-color: #2B3035;
  padding: 10px;
}

/* KeyServices.css */

.title-container {
  display: flex;
  justify-content: start;
  margin-top: 150px;
  margin-bottom: 15px;
}

.offcanvas-body {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.social-icons {
  margin-top: auto; /* Push the social icons to the bottom */
}

.social-icon {
  height: 30px;
  color: #EF8354;
  margin-left: 12px;
}

.max-height-400 {
  max-height: 400px;
}
@media screen and (max-width: 840px) {
  .textStyle-responsive {
    font-size: 46px;
  }


  .offcanvas-body {
    display: flex;
    align-items: center;
  }

  .social-icon {
    height: 40px;
  }

  .metallist {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .imageeffect {
    filter: grayscale(0%);
  }

  .textStyle-responsive {
    width: 90%;
  }
}